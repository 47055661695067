<template>
    <div class="cm_page">
        <div class="cm_main">
            <div class="cm_nav">
                <el-breadcrumb class="crumb" separator="/">
                    <el-breadcrumb-item :to="{ path: '/bus-customManage' }">客户管理</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/customAdd' }">添加客户</el-breadcrumb-item>
                </el-breadcrumb>
                <el-button type="primary" @click="add">保存</el-button>
            </div>
            <el-form class="addform" ref="form" :model="form" label-width="80px" :rules="dataRule">
                <!-- <el-form-item label="客户编号">
                    <el-input></el-input>
                </el-form-item> -->
                <el-form-item label="客户名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
            </el-form>
        </div>
        
    </div>
</template>
<script>
  export default {
    data () {
      return {
        form: {},
        dataRule: {
          name: [
            {required: true, message: '客户名称不能为空'}
          ]
        }
      }
    },
    methods: {
      add() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl('/bus/buscustom/save'),
              method: 'post',
              data: this.$http.adornParams(this.form)
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$router.go(-1)
              }
            })
          }
        })
      }
    },
    created() {
  
    }
  }
</script>
<style scoped lang="scss">
    .cm_main{
        text-align: center;
    }
    .addform{
        max-width: 400px;
        margin: 0 auto;
        margin-top: 20px;
    }
</style>